<template>
  <div style="height: 100vh; overflow: scroll">
    <div>
      <div class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading...">
        <div class="new-item-head">
          Conversion Import {{ titleType }}
          <span
            style="position: absolute; left: 10px; cursor: pointer"
            @click="back">
            <i class="el-icon-arrow-left"></i>
          </span>
          <span
            style="
              position: absolute;
              right: 10px;
              cursor: pointer;
              font-weight: 400;
              font-size: 14px;
            "
          >
            <el-dropdown placement v-if="userInfo.username">
              <div>
                <i class="el-icon-user" style="margin-right: 10px"></i>
                {{ userInfo.username }}
                <i class="el-icon-arrow-down"></i>
                <!-- <span class="user-name">语言切换</span> -->
                <el-dropdown-menu class="user-dropdown" slot="dropdown">
                  <el-dropdown-item @click.native="logoutHandle"
                    >Logout</el-dropdown-item
                  >
                </el-dropdown-menu>
              </div>
            </el-dropdown>
          </span>
        </div>
      </div>
      <div
        style="
          background: white;
          margin-top: 40px;
          width: 90%;
          margin-left: 2.5%;
          padding: 40px;
        "
      >
        <div
          style="font-size: 20px; float: left; height: 20px; margin-left: 20px"
        >
          New Conversion
        </div>
        <div style="margin-top: 40px"></div>
        <center>
          <el-form
            label-position="right"
            label-width="310px"
            style="width: 900px; align: center"
            ref="formModel"
            :model="formModel"
          >
            <el-form-item label="转换前的产品编码">
              <el-autocomplete
                clearable
                ref="oldItemCode"
                v-model="formModel.oldItemCode"
                :fetch-suggestions="codeSearch"
                placeholder="Please Input"
                popper-class="select-option"
                :popper-append-to-body="false"
                style="width: 100%"
                @select="itemCodeSearch"
                @blur="itemCodeSearch"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item label="新分配的产品编码">
              <el-input
                v-model="formModel.newItemCode"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="产品名称">
              <el-input
                v-model="formModel.productName"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="formModel.note"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="操作人">
              <el-input
                v-model="formModel.updateName"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
          </el-form>
            <div style="margin-top: 20px">
              <el-dropdown style="margin-right:10px;">
                <el-button type="primary">
                  预览<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="previewType('Spec')">Spec</el-dropdown-item>
                  <el-dropdown-item @click.native="previewType('SDS')">SDS</el-dropdown-item>
                  <!-- <el-dropdown-item @click.native="previewType('Composition')">Composition</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
              <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew()">提交</el-button>
              <el-button
                v-if="userInfo.username === 'JamesChen' && convId > 0"
                type="warning"
                @click="handleConfirm()"
                >审核</el-button>
              <el-button @click="back">返回</el-button>
            </div>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";

export default {
  name: "new-item",
  mixins: [base],
  data() {
    return {
      loading: false,
      titleType: "Add",
      formModel: {
        oldItemCode: "",
        newItemCode: "",
        productName: "",
        note: "",
        language:1,
        confirmed:2,
        verifiedBy:"",
        createName:"",
        updateName:"",
        createTime: base.methods.getLocalTime(-7),
        updateTime: base.methods.getLocalTime(-7),
      },
      itemCodeSearchLock: "",
      previewTypeList:[
        {
          value: 'Spec',
          label: 'Spec'
        },
        {
          value: 'SDS',
          label: 'SDS'
        },
        {
          value: 'Composition',
          label: 'Composition'
        },
      ],
      oldItemInfo:{},
      convId:0,
    };
  },
  computed: {
  },
  watch: {},
  methods: {
    back() {
      this.$router.push({ path: "cn-home"});
    },
    codeSearch(queryString, cb) {
      var codeList = JSON.parse(localStorage.getItem("itemCodeCnListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    itemCodeSearch(val) {
      if (typeof val.value != "undefined") {
        console.info(val.value);
        this.getDetailByItemCodeSearch(val.value);
      }
    },
    getDetailByItemCodeSearch(searchValue) {
      if (this.itemCodeSearchLock == searchValue) {
        this.itemCodeSearchLock = searchValue;
        return;
      }
      if (typeof searchValue == "undefined" || searchValue.length == 0) {
        return;
      }

      this.getConversionPreview(searchValue,1)
    },
    getConversionPreview(searchValue,type){
      this.loading = true;
      this.$api.handleConversion
      .conversionPreview({itemCode:searchValue,language:1})
      .then((res) => {
        if (res != null) {
          console.log("res");
          console.log(res);
          if(type == 1){
            this.formModel.productName = res.specProductName;
          }
          this.oldItemInfo = res
          this.loading = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
    getDetailById(params) {
      this.loading = true;
      this.$api.handleConversion.conversionInfo(params).then((data) => {
        this.loading =false;
        this.formModel = data;
        console.log("conversionInfo====this.formModel")
        console.log(this.formModel)
        this.getConversionPreview((this.formModel.confirmed == 1 || this.formModel.verifiedBy) ? this.formModel.newItemCode : this.formModel.oldItemCode,2)
      });
    },
    async handleNew() {
      this.loading = true;
      var params = JSON.parse(JSON.stringify(this.formModel));
      console.info(params);
      if (params.id != null) {
        this.$api.handleConversion
          .conversionUpdate(params)
          .then(() => {
            this.loading = false;
            var that = this
            this.$message({
              type: "success",
              message: "保存成功",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/cn-home' })
              }
            });
            this.titleType = "Edit";
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        this.formModel.createName = this.formModel.updateName
        this.$api.handleConversion
          .conversionAdd(params)
          .then(() => {
            this.loading = false;
            var that = this
            this.$message({
              type: "success",
              message: "保存成功",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/cn-home' })
              }
            });
            this.titleType = "Add";
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    handleConfirm(){
      if (this.convId != 0) {
        this.$confirm(
          "确定要审核吗?",
          "Tips",
          {
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
            distinguishCancelAndClose: true,
            type: "warning",
          }
        ).then(() => {
          this.$api.handleConversion
            .conversionConfirm({'id':this.convId,'note':this.formModel.note,language:1})
            .then(() => {
              this.loading = false;
              var that = this
              this.$message({
                type: "success",
                message: "审核成功",
                duration: 1000,
                onClose: function () {
                  that.$router.push({ path: '/cn-home' })
                }
              });
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        });
      }
    },
    previewType(dataType){
      console.log(dataType)
      if (dataType === "Spec") {
         const link = this.$router.resolve({
          path: 'cn-detail',
          query: { id: this.oldItemInfo.specId,productType:this.oldItemInfo.specProductType }
        })
        window.open(link.href, "_blank");
      }
      else if (dataType === "SDS") {
         const link = this.$router.resolve({
          path: 'cn-detail-sds',
          query: { id: this.oldItemInfo.sdsId }
        })
        window.open(link.href, "_blank");
      }
    }
  },
  created() {
    let specUserInfo = JSON.parse(localStorage.getItem('specUserInfo'))
    if(specUserInfo){
      this.formModel.updateName = specUserInfo.username;
    }
    let params = { ...this.$route.query };
    if (params.id) {
      this.convId = params.id
      this.getDetailById(params.id);
      this.titleType = "Edit";
    } else {
      this.titleType = "Add";
    }
  },
};
</script>

<style lang="scss" scoped>
.app-new-item {
  .el-autocomplete ::v-deep .el-input__inner {
    width: 600px;
  }
  // width: 100%;
  // overflow: scroll;
  overflow-x: auto !important;
  overflow-y: auto;
  padding-top: 80px;
  .el-input,
  .el-select {
    width: 600px;
  }
  .area-input {
    height: 90px !important;
    line-height: 90px !important;
  }
  .new-item-head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 99;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
}
</style>